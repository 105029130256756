// eslint-disable-next-line
import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import mackinacBold from "@assets/font/P22MackinacPro-Bold_16.woff";
import mackinacBoldItalic from "@assets/font/P22MackinacPro-BoldItalic_11.woff";
import mackinacExBold from "@assets/font/P22MackinacPro-ExtraBold_12.woff";
import mackinacExBoldItalic from "@assets/font/P22MackinacPro-ExBoldItalic_17.woff";
import { COLORS, DEFAULT_COLOR_VARIANTS } from "./colors";

export const primaryFontFamily = "Inter, sans-serif";
export const secondaryFontFamily = "Mackinac Pro, sans-serif";

/**
 * @component GlobalStyle Stylesheet for Apps
 */

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @font-face {
    font-family: 'Mackinac Pro';
    src: url(${mackinacBold}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mackinac Pro';
    src: url(${mackinacBoldItalic}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Mackinac Pro';
    src: url(${mackinacExBold}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mackinac Pro';
    src: url(${mackinacExBoldItalic}) format('woff');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz@0,14..32;1,14..32&display=swap');
    font-weight: normal;
    font-style: normal;
  }

  H2 {
    font-family: 'Mackinac Pro', sans-serif;
    font-size: 3rem;
    font-weight: bold;
  }

  H3 {
    font-family: 'Mackinac Pro', sans-serif;
    font-size: 2.625rem;
    font-weight: bold;
  }

  H4 {
    font-family: 'Mackinac Pro', sans-serif;
    font-size: 2rem;
    font-weight: bold;
  }

  h5 {
    font-family: 'Mackinac Pro', sans-serif;
    font-size: 1rem;
    font-weight: 800;
  }

  h6 {
    font-family: 'Mackinac Pro', sans-serif;
    font-size: 1.25rem;
    font-weight: 800;
  }

  body {
    font-size: 16px;
    font-family: ${primaryFontFamily};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: ${(props) => (props.crlite ? "white" : COLORS.white)};
    min-height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: ${DEFAULT_COLOR_VARIANTS.secondary};
    cursor: pointer;
    text-decoration: none;
  }

  a:focus {
    outline: ${DEFAULT_COLOR_VARIANTS.secondary} auto 1px;
  }

  .Nav {
    padding: 0 0.5rem;
    text-decoration: none;
    color: ${COLORS.white};
    opacity: 0.6;

    > svg {
      fill: ${COLORS.white};
    }

    &:hover {
      opacity: 1;
    }
  }
  .Nav.is-active {
    color: ${COLORS.white};
    opacity: 1;

    h4 {
      display: block;
    }
  }
  .acsb-trigger {
    visibility: hidden !important;
  }
  .acsb-trigger-icon {
    display: none !important;
  }

  .MuiTablePagination-toolbar p {
    margin: 0 !important;
  }
`;

export default GlobalStyle;
