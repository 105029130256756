import { defaultAmenifyData, defaultTrackingAttributes, globalState, productData } from "./utils";
import { CLICK, VIEW, BENEFITS } from "./constants";

export function amenifyFakeDoorShownTrackingAttributes() {
  return {
    page: BENEFITS,
    event: VIEW,
    context: "AmenifyFakeDoorShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function amenifyFakeDoorClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "AmenifyFakeDoorClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function amenifyFakeDoorAsideShownTrackingAttributes() {
  return {
    page: BENEFITS,
    event: VIEW,
    context: "AmenifyFakeDoorAsideShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function amenifyWaitListClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "AmenifyWaitListClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      email: globalState().resident.email,
    },
  };
}

export function lifeStyleCardShownTrackingAttributes() {
  return {
    page: BENEFITS,
    event: VIEW,
    context: "LifeStyleCardShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      amenifyOptedIn: globalState().player.amenifyOptedIn,
    },
  };
}

export function lifeStyleCardClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "LifeStyleCardClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      amenifyOptedIn: globalState().player.amenifyOptedIn,
    },
  };
}

export function lifeStyleAsideShownTrackingAttributes() {
  return {
    page: BENEFITS,
    event: VIEW,
    context: "LifeStyleAsideShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      amenifyOptedIn: globalState().player.amenifyOptedIn,
    },
  };
}

export function lifeStyleOptInClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "LifeStyleOptInClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function lifeStyleExploreClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "LifeStyleExploreClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
    },
  };
}

export function travelFakeDoorShownTrackingAttributes() {
  return {
    page: BENEFITS,
    event: VIEW,
    context: "TravelFakeDoorShown",
    ...defaultTrackingAttributes(),
    data: productData(),
  };
}

export function travelFakeDoorClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "TravelFakeDoorClick",
    ...defaultTrackingAttributes(),
    data: productData(),
  };
}

export function travelFakeDoorAsideShownTrackingAttributes() {
  return {
    page: BENEFITS,
    event: VIEW,
    context: "TravelFakeDoorAsideShown",
    ...defaultTrackingAttributes(),
    data: productData(),
  };
}

export function travelWaitListClickTrackingAttributes() {
  return {
    page: BENEFITS,
    event: CLICK,
    context: "TravelWaitListClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      email: globalState().resident.email,
    },
  };
}
