import ReactOnRails from "react-on-rails";
import Cookies from "js-cookie";
import { v4 as generateUuid } from "uuid";
import { isAlloyApp } from "@utils/isMobile";

function handleOldSessionCookie() {
  const oldCookieName = "cr_session_id"; // TODO: Remove after all old cookies have been flushed
  const oldCookie = Cookies.get(oldCookieName);
  if (oldCookie) {
    Cookies.remove(oldCookieName, { path: "/" });
  }
}

export function getOrSetCookie() {
  const cookieName = "csid";
  const sessionId = generateUuid();
  const inThirtyMinutes = 1 / 48;
  const cookieSettings = { expires: inThirtyMinutes, path: "/" };

  handleOldSessionCookie();

  let currentCookie = Cookies.get(cookieName);
  if (!currentCookie) {
    Cookies.set(cookieName, sessionId, cookieSettings);
    currentCookie = Cookies.get(cookieName);
  }
  return currentCookie;
}

/**
 * Returns the full redux store for data not available in props of a particular component
 * Mainly used for Atoms/Molecules/Organisms
 */
export const globalState = () => ReactOnRails.getStore("rewardsStore").getState();
export const globalDashboardState = () => ReactOnRails.getStore("dashboardStore").getState();

export function getExperiments() {
  if (window?.vanity?.current_experiments && Object.entries(window?.vanity?.current_experiments).length) {
    return Object.entries(window?.vanity?.current_experiments)
      .map((exp) => {
        if (exp?.[0]) {
          return {
            [exp[0]]: exp?.[1]?.name,
          };
        }
        return {};
      })
      .reduce((a, cv) => ({ ...a, ...cv }));
  }
  return {};
}

/**
 * Function to set data that will be passed to all events
 * @param {*} props
 */
export const defaultTrackingAttributes = (dashboard = false) => {
  const sessionId = getOrSetCookie();
  const timestamp = new Date().toUTCString();
  if (window.Cypress) {
    return {};
  }
  return {
    experiments: getExperiments(),
    event_timestamp: timestamp,
    session_id: sessionId,
    resident_id: dashboard ? "" : globalState().resident.id,
    player_id: dashboard ? "" : globalState().player.id,
    user_id: dashboard ? "" : globalState().player.userId,
    property_id: dashboard ? globalDashboardState().property.id : globalState().property.id,
  };
};

export const productData = () => {
  const product = isAlloyApp() ? "ab" : "cr";

  return {
    product,
    propertyId: globalState().property.id,
    managementId: globalState().management.id,
  };
};

export const defaultAmenifyData = () => ({
  pmcId: globalState().resident.pmcId,
  playerId: globalState().player.id,
});

export const idCleaner = (data, parentObject = null) => {
  const object = parentObject;
  if (typeof data === "string" && object) {
    if (data === "id") {
      object.identifier = object[data];
      delete object.id;
    } else if (Array.isArray(object[data])) {
      idCleaner(object[data]);
    } else if (typeof object[data] === "object" && object[data] !== null && !object[data].hasBeenCleaned) {
      if (!Array.isArray(object) && typeof object === "object") {
        object.hasBeenCleaned = true;
      }
      idCleaner(object[data]);
    }
  } else if (typeof data === "object" && data !== null) {
    Object.keys(data).forEach((key) => {
      idCleaner(key, data);
    });
    const cleanedData = data;
    delete cleanedData.hasBeenCleaned;
  } else if (Array.isArray(data)) {
    data.forEach((item) => {
      idCleaner(item);
    });
  }
};

export default {
  globalState,
  defaultTrackingAttributes,
  idCleaner,
  productData,
};
